import { atom } from 'recoil';
import { Activity, findRequest, Lesson, Member } from '../models';
import { recoilPersist } from 'recoil-persist';
import { Units } from '../models/Course';
const { persistAtom } = recoilPersist();
export const checkUser = atom<boolean>({
  key: 'checkUser',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
export const UserInfo = atom<Member | undefined>({
  key: 'UserInfo',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const curActivity = atom<Activity | undefined>({
  key: 'cur_activity',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const userToken = atom<string | undefined>({
  key: 'user_token',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const signupData = atom<string | undefined>({
  key: 'signup_data',
  default: undefined,
});
export const findData = atom<string>({
  key: 'find_data',
  default: '',
});
export const findClass = atom<string>({
  key: 'find_class',
  default: '',
});
export const findCata = atom<findRequest>({
  key: 'find_cata',
  default: {
    languageFocus: '',
    lessonFocus: '',
    skillFocus: '',
    activity_Type: '',
    classId: '',
  },
  effects_UNSTABLE: [persistAtom],
});
export const findSubCata = atom<string[]>({
  key: 'find_sub_cata',
  default: [],
});
export const CurLesson = atom<Lesson>({
  key: 'current_lesson',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const curClass = atom<string>({
  key: 'cur_class',
  default: '',
});
export const securityKey = atom<string | undefined>({
  key: 'security_key',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const dashboardActivityPage = atom<number>({
  key: 'activity_page',
  default: 1,
});
export const dashboardLessonPage = atom<number>({
  key: 'lesson_page',
  default: 1,
});
export const dashboardActivityFirst = atom<boolean>({
  key: 'activity_first',
  default: true,
});
export const dashboardLessonFirst = atom<boolean>({
  key: 'lesson_first',
  default: true,
});
export const addClassShow = atom<boolean>({
  key: 'add_class_show',
  default: false,
});
export const addFolderShow = atom<boolean>({
  key: 'add_show_show',
  default: false,
});
export const selectActivityId = atom<string>({
  key: 'select_add_activity_id',
  default: '',
});
export const selectLessonId = atom<string>({
  key: 'select_add_lesson_id',
  default: '',
});

export const selectedActivityInLesson = atom<Activity | undefined>({
  key: 'selected_Activity_in_lesson',
  default: undefined,
});
export const showUnverified = atom<boolean>({
  key: 'show_unverified_modal',
  default: false,
});
export const confirmAdaptShow = atom<boolean>({
  key: 'show_confirm_adapt_modal',
  default: false,
});
export const orgActivityInAdapt = atom<Activity | undefined>({
  key: 'org_Activity_in_Adapt',
  default: undefined,
});
export const curFolder = atom<string>({
  key: 'cur_folder',
  default: '',
});
export const curItem = atom<string>({
  key: 'current_item',
  default: '',
});
export const adaptId = atom<string | undefined>({
  key: 'adapt_activity_id',
  default: undefined,
});
