import { MainLayout } from "../components/Layouts/MainLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUnitInfo } from "../services/Query";
import { convertContentDefaultImageSize } from "../services/Utils";

const ViewUnit = () => {
  let { id: unit_id } = useParams();
  const navigate = useNavigate();
  const { data: unitInfo, isLoading: isLoading } = useGetUnitInfo(unit_id);

  return (
    <MainLayout>
      <div className="top_blue_action_bar  py-2">
        <div className="container">
          <button
            className="back_btn_o"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={white_arrow} alt="" /> back
          </button>
        </div>
      </div>
      <div className="edit_box mx-auto py-3 fnt_robo">
        <div className="row gy-3">
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>unit name</b>
              </Form.Label>
              <p>{unitInfo?.name}</p>
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>key ideas</b>
              </Form.Label>
              {unitInfo?.keyIdea?.map((it, idx) => (
                <li key={it}>{it}</li>
              ))}
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Label className="fw-bold fnt_robo">
              companion printed title(s)
            </Form.Label>
            <div className="row">
              <div className="col-7">
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    readOnly
                    value={unitInfo?.companion}
                  />
                </InputGroup>
              </div>
              <div className="col">
                <InputGroup className="mb-3">
                  <InputGroup.Text>pages or page range</InputGroup.Text>
                  <Form.Control type="text" readOnly value={unitInfo?.pages} />
                </InputGroup>
              </div>
            </div>
          </div>
          <div className="col-12">
            <b>unit description</b>
            <p
              dangerouslySetInnerHTML={{
                __html: convertContentDefaultImageSize(
                  unitInfo?.description || ""
                ),
              }}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default ViewUnit;
