import { useEffect } from "react";

import { NoFooterLayout } from "../components/Layouts/NoFooterLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios, { AxiosError } from "axios";
import { securityKey, UserInfo } from "../store";
import { useRecoilState, useRecoilValue } from "recoil";
import google_icon from "../assets/images/icons/icons8-google-96.svg";
import { useGSignin } from "../services/Query";
import { useGoogleLogin } from "@react-oauth/google";
import TokenService from "../services/TokenService";

interface tokenType {
  token: string;
}
type LoginValues = {
  email: string;
  password: string;
};
/**
 *
 * Login page : /login
 */
export const Login = () => {
  const app_client_id = process.env.REACT_APP_CLIENT_ID;
  const navigate = useNavigate();
  // const { mutate: onLogin } = useLogin();
  const { mutate: onSignin } = useGSignin();
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const secKey = useRecoilValue(securityKey);
  const { handleSubmit } = useForm<LoginValues>();
  useEffect(() => {
    if (userinfo) navigate("/dashboard");
  }, [userinfo]);
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );
      const vData = userInfo.data;
      const data = {
        email: vData.email,
        firstName: vData.given_name,
        lastName: vData.family_name,
        googleId: vData.sub,
      };
      onSignin(JSON.stringify(data), {
        onSuccess: (res) => {
          const { member, token } = res;
          setUserinfo(member);
          console.log(member);
          TokenService.updateLocalAccessToken(token);
          if (member.member_Verified) {
            navigate("/dashboard");
          } else {
            navigate("/ramp");
          }
        },
        onError: (err) => {
          const res = err as AxiosError;
          const tokenObj = res.response?.data as tokenType;
          if (tokenObj) {
            navigate(`/verify/${tokenObj.token}`);
          }
        },
      });
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <NoFooterLayout topClass="box_shadow">
      <div className="signup_wrap">
        <button
          className="back_btn"
          onClick={() => {
            navigate("..", { replace: true });
          }}
        >
          <img src={white_arrow} alt="" /> back
        </button>
        <Container className="pt-5">
          <Card className="sign_card mx-auto">
            <Card.Header>Sign in with Google</Card.Header>
            <Card.Body>
              <div className="mt-4">
                <Container>
                  <div className="d-flex justify-content-center mt-5">
                    <button
                      onClick={() => {
                        googleLogin();
                      }}
                      className="d-flex py-3 text-black align-items-center px-3 w_auto justify-content-center"
                    >
                      <img
                        width={30}
                        // style="margin-top:7px; margin-right:8px"
                        alt="Google sign-in"
                        src={google_icon}
                      />
                      <h6 className="mb-0 ms-3">sign in with Google</h6>
                    </button>
                  </div>
                </Container>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </NoFooterLayout>
  );
};
