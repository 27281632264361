import { Container, Modal, Spinner } from "react-bootstrap";
import close from "../../assets/images/icons/red_close.svg";
interface Props {
  show: boolean;
  isDeleting: boolean;
  message: string;
  onOk: () => void;
  onDiscard: () => void;
}
export const ConfirmRemoveModal = (props: Props) => {
  const { show, message, onOk, onDiscard, isDeleting } = props;
  return (
    <Modal
      show={show}
      className="dashboard_video"
      centered={true}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          warning!
          <img
            src={close}
            alt=""
            onClick={() => {
              props.onOk();
            }}
            className="closeBtn d-none"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <div className="my-3">
            <p
              className="text-center fs-5"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>

          <div className="d-flex justify-content-evenly pt-3">
            <button
              className="bg_warn rounded_3 py-2 px-4 fs-4"
              onClick={() => onOk()}
            >
              no
            </button>
            <button
              className=" border_warn bg-white rounded_3 py-2 px-4 fs-4"
              onClick={() => onDiscard()}
            >
              {isDeleting && (
                <Spinner animation="border" size={"sm"} className="me-1" />
              )}
              yes
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
