import { Container, Modal } from "react-bootstrap";
interface Props {
  show: boolean;
  onOk: () => void;
}
export const GoBackModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>feature under construction</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <div className="my-3">
            <p className="text-center fs-4">coming soon!</p>
          </div>

          <div className="d-flex justify-content-evenly pt-3">
            <button
              className="bg_warn rounded_3 py-2 px-4 fs-4"
              onClick={() => props.onOk()}
            >
              go back to dashboard
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
