import { MainLayout } from "../components/Layouts/MainLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Form, InputGroup } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useGetCourseInfo } from "../services/Query";
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
const ViewCourse = () => {
  let { id: course_id } = useParams();
  const navigate = useNavigate();
  const { data: courseInfo, isLoading } = useGetCourseInfo(course_id);

  return (
    <MainLayout>
      <div className="top_blue_action_bar py-2">
        <div className="container">
          <button
            className="back_btn_o"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={white_arrow} alt="" /> back
          </button>
        </div>
      </div>
      <div className="edit_box mx-auto py-3">
        <div className="row gy-3 fnt_robo">
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>course name</b>
              </Form.Label>
              <h6>{courseInfo?.course_Name}</h6>
            </Form.Group>
          </div>
          {courseInfo?.publisher_icon && (
            <div className="col-12">
              <Form.Group>
                <Form.Label>
                  <b>publisher logo</b>
                </Form.Label>
                <img
                  src={`${backend_baseUrl}/download/${courseInfo?.publisher_icon.file_Path}`}
                  alt=""
                  width={200}
                  className="d-block"
                />
              </Form.Group>
            </div>
          )}
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>subject/domain</b>
              </Form.Label>
              <h6>{courseInfo?.course_Subject}</h6>
            </Form.Group>
          </div>
          <div className="col-12">
            <Form.Label className="fw-bold fnt_robo">
              companion print title(s)
            </Form.Label>
            {courseInfo?.companions?.map((it, idx) => (
              <div className="row mb-2" key={idx}>
                <div className="col-7">
                  <InputGroup>
                    <InputGroup.Text>title</InputGroup.Text>
                    <Form.Control type="text" value={it.title} readOnly />
                  </InputGroup>
                </div>
                <div className="col">
                  <InputGroup>
                    <InputGroup.Text>ISBN</InputGroup.Text>
                    <Form.Control type="text" value={it.isbn} readOnly />
                  </InputGroup>
                </div>
              </div>
            ))}
          </div>
          <div className="col-12">
            <Form.Group>
              <Form.Label>
                <b>target grade level(s)</b>
              </Form.Label>
              <div>{courseInfo?.course_Levels.join(", ")}</div>
            </Form.Group>
          </div>
          <div className="col-12">
            <b>course description</b>
            <p
              dangerouslySetInnerHTML={{
                __html: courseInfo?.course_Description || "",
              }}
            ></p>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default ViewCourse;
