import { Container } from "react-bootstrap";
import { MainLayout } from "../components/Layouts/MainLayout";
import friend1 from "../assets/images/images/friend1.png";
import friend2 from "../assets/images/images/friend2.png";
import friend3 from "../assets/images/images/friend3.png";
import friend4 from "../assets/images/images/friend4.png";
import friend5 from "../assets/images/images/friend5.png";
import friend6 from "../assets/images/images/friend6.png";
import friend8 from "../assets/images/images/friend8.png";
import folder_icon from "../assets/images/icons/symbol_folder.svg";
import back_icon from "../assets/images/icons/white-left-arrow.svg";
import comment_icon from "../assets/images/icons/symbol_comment.svg";
import { useNavigate } from "react-router-dom";
import { ProjectItem } from "../components/ProjectItem";

export const CollaborateDetails = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <div className="template_wrap">
        <div className="header">
          <button
            className="back_btn"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={back_icon} alt="" /> back
          </button>
          <div>
            <h4>K2 Teachers 2021</h4>
            <div className="comment">we are each other’s greatest resource</div>
          </div>
          <button className="go_btn comment_btn">
            <img
              src={comment_icon}
              alt=""
              onClick={() => {
                navigate("/build/collaborate/chat");
              }}
            />
          </button>
        </div>
        <div className="body py-3">
          <Container>
            <h3>Members 8</h3>
            <div className="d-flex flex-wrap ">
              <div className="img_item selected">
                <img src={friend5} alt="" />
              </div>
              <div className="img_item">
                <img src={friend1} alt="" />
              </div>
              <div className="img_item">
                <img src={friend2} alt="" />
              </div>
              <div className="img_item">
                <img src={friend3} alt="" />
              </div>
              <div className="img_item">
                <img src={friend4} alt="" />
              </div>

              <div className="img_item">
                <img src={friend6} alt="" />
              </div>
              <div className="img_item">
                <img src={friend8} alt="" />
              </div>
              <div className="img_item">
                <div className="red_circle"></div>
              </div>
            </div>
            <h3 className="mt-4">Projects 3</h3>
            <div className="">
              <div className="row mx-0">
                <div className="col-md-6 col-lg-4">
                  <ProjectItem
                    kindClass="default"
                    icon={friend5}
                    title={`Ideas for Vocab Teaching`}
                    description={`Please submit your best vocabulary teaching ideas here so we can build a bank together.`}
                  />
                </div>
                <div className="col-md-6 col-lg-4">
                  <ProjectItem
                    kindClass="default"
                    icon={friend2}
                    title={`Materials Class Fall 2020`}
                    description={`Here is where you’ll submit your activity templates for this semester’s Materials class.`}
                  />
                </div>
                <div className="col-md-6 col-lg-4">
                  <ProjectItem
                    kindClass="default"
                    icon={friend1}
                    title={`Countable/Uncountable`}
                    description={`Please submit your best countable/uncounctable distinction teaching ideas here so we can build a bank together.`}
                  />
                </div>
              </div>
            </div>
            <h3 className="mt-4">Folders 3</h3>
            <div>
              <div className="row mx-0">
                <div className="col-md-6 col-lg-4">
                  <div className="folder_box">
                    <img src={folder_icon} alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="folder_box">
                    <img src={folder_icon} alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="folder_box">
                    <img src={folder_icon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </MainLayout>
  );
};
