import { Container, Modal } from "react-bootstrap";
import close from "../../assets/images/icons/red_close.svg";
import { MessageString } from "../../models/defaults";
interface Props {
  show: boolean;
  onOk: () => void;
}
export const VerifyUserModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>
          warning!
          <img
            src={close}
            alt=""
            onClick={() => {
              props.onOk();
            }}
            className="closeBtn"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <div className="my-3">
            <p className="text-center fs-5">{MessageString.confirmVerify}</p>
          </div>

          <div className="d-flex justify-content-evenly pt-3">
            <button
              className="bg_warn rounded_3 py-2 px-4 fs-4"
              onClick={() => props.onOk()}
            >
              Close
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
