import { useRef, useState } from "react";
import { Card, Overlay } from "react-bootstrap";
import vdots from "../assets/images/icons/vdot.svg";
import adapt_icon from "../assets/images/images/adapt.png";
import build_icon from "../assets/images/images/build.png";
import poper_icon from "../assets/images/icons/poper_icon.svg";
interface Props {
  kindClass?: string;
  title?: string;
  icon: string;
  prep?: string;
  classTime?: string;
  description?: string;
  isAdapt?: boolean;
  score?: number;
}

export const ActivityItem = (props: Props) => {
  const target = useRef(null);
  const [show, setShow] = useState(false);
  return (
    <Card className={`${props.isAdapt ? "adapt" : "default"} mx-auto`}>
      <Card.Header className="d-flex justify-content-between">
        <div className="title_box" onClick={() => {}}>
          <img src={props.icon} alt="" height={24} />
          <b className="ms-1">{props.title}</b>
        </div>

        <img
          src={vdots}
          alt=""
          height={20}
          ref={target}
          onClick={() => setShow(!show)}
        />
        <Overlay target={target.current} show={show} placement="bottom-end">
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              className="item_control_overlay"
              style={{
                ...props.style,
              }}
            >
              <div className="header my-2 d-flex justify-content-end">
                <img src={poper_icon} alt="" onClick={() => setShow(false)} />
              </div>
              <div className="content">
                <h5>ADD TO CLASS {">"} </h5>
                <h5>ADD TO FOLDER {">"} </h5>
              </div>
            </div>
          )}
        </Overlay>
      </Card.Header>
      <Card.Body>
        <div className="d-flex">
          <div className="content">
            <div>
              <span>
                {props.prep} <b>prep</b>
              </span>
              <span className="ms-2">
                {props.classTime} <b>class time</b>
              </span>
            </div>
            <p>{props.description}</p>
          </div>
          <div className="controls d-flex align-content-center flex-wrap">
            {props.score && (
              <div className="my-3">
                <h6
                  className={`text-center score ${
                    props.score > 70 ? "score_green" : "score_warn"
                  }`}
                >
                  {Number(props.score).toFixed(1)}
                  {"%"}
                </h6>
              </div>
            )}
            <div className="my-3">
              <img src={adapt_icon} className="mx-auto" alt="" />
            </div>
            <div className="my-3">
              <img src={build_icon} className="mx-auto" alt="" />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
