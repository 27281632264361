import { Container, Modal } from "react-bootstrap";
interface Props {
  show: boolean;
  title: string;
  message: string;
  onOk: () => void;
}
export const PromptModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video"
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <div className="my-3">
            <p className="text-center fs-4">{props.message}</p>
          </div>

          <div className="d-flex justify-content-evenly pt-3">
            <button
              className="bg_warn rounded_3 py-2 px-4 fs-4"
              onClick={() => props.onOk()}
            >
              ok
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
