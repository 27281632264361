import { Layout } from "../components/Layouts/Layout";
import thanks_img from "../assets/images/images/thanks.png";
import { useNavigate } from "react-router-dom";
/**
 *
 * Home page : /
 */
export const Home = () => {
  return (
    <Layout>
      <div className="dashboard_1 position-relative">
        <div className="whiteboard">
          <div className="ps-5">
            <h3 className="mt-4">
              As a teacher, your time and energy are <br />
              too valuable to waste.
            </h3>
            <p className="mb-0 mt-3">
              So we’re building a curriculum hub and workspace that puts
              everything <br />
              you need to plan and prep within reach.
            </p>
            <p className="mb-0 mt-2">
              As one of our earliest users, you are a vital part of this
              process.
            </p>
            <div className="me-2 d-flex justify-content-end pe-5">
              <img
                src={thanks_img}
                alt=""
                className="align-self-center"
                height={80}
              />
            </div>
          </div>
          {/* <button
          // onClick={() => {
          //   navigate('/signup');
          // }}
          >
            join the beta.
          </button> */}
        </div>
        <div className="pink_alert"></div>
      </div>
      {/* <div className='how_it_board mt-5 container'>
        <h3>How it works </h3>
        <div className='arrow_box row'>
          <div className='col'>
            <img src={dotted_line_a} alt='' className='d-inline' width={302} />
          </div>
          <div className='col'>
            <img src={magnifting} alt='' />
          </div>
        </div>
        <div className='comment_box row px-0'>
          <div className='col'>
            <img src={find_img} alt='' height={450} className='shadow_effect' />
          </div>
          <div className='col ps-lg-4'>
            <h3 className='text-start'>Find</h3>
            <p className='text-start'>
              Activities for your lessons, with <br /> search results that
              reflect the <br /> uniqueness of your teaching <br />
              context.
            </p>
          </div>
        </div>
        <div className='arrow_box row'>
          <div className='col-3'>
            <img src={gears_1} alt='' width={144} />
          </div>
          <div className='col-9 text-start'>
            <img
              src={dotted_line_B}
              alt=''
              className='d-inline resp_line'
              width={602}
            />
          </div>
        </div>
        <div className='comment_box row px-0'>
          <div className='col pe-lg-4'>
            <h3 className='text-end'>Adapt</h3>
            <p className='text-end'>
              We've made adapting activity ideas <br /> to fit your students
              learning <br />
              goals easy. Everything you find can <br /> be used as a template!
            </p>
          </div>
          <div className='col'>
            <img
              src={adapt_img}
              alt=''
              height={450}
              className={'shadow_effect'}
            />
          </div>
        </div>
        <div className='arrow_box row'>
          <div className='col-9 text-end'>
            <img
              src={dotted_line_C}
              alt=''
              className='d-inline resp_line'
              width={602}
            />
          </div>
          <div className='col-3'>
            <img src={puzzle_pieces} className='w-100' alt='' />
          </div>
        </div>
        <div className='comment_box row px-0'>
          <div className='col'>
            <img
              src={build_img}
              alt=''
              height={450}
              className={'shadow_effect'}
            />
          </div>
          <div className='col ps-lg-4'>
            <h3 className='text-start'>Build</h3>
            <p className='text-start'>
              Assemble effective lesson plans <br /> quickly: adding your
              favorite <br /> activities, aligning objectives, and <br />{' '}
              printing materials-all in one place!
            </p>
          </div>
        </div>
        <div className='arrow_box row'>
          <div className='col-9 text-center'>
            <img
              src={dotted_line_D}
              alt=''
              className='d-inline resp_line5'
              width={302}
            />
          </div>
          <div className='col-3'></div>
        </div>
        <h3 className='mt-3'>Get Started</h3>
        <p className='text-center more_alert'>
          More efficient and effective lesson planning is just a click away.
        </p>
      </div> */}
      {/* <div className='bottom_box text-center'>
        <div className='comp_moniter'>
          <div className='desk_moniter'>
            <button
              onClick={() => {
                navigate('/signup');
              }}
            >
              Sign up now
            </button>
          </div>
        </div>
      </div> */}
    </Layout>
  );
};
