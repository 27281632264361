import { ChangeEvent, useEffect, useState } from "react";
import { MainLayout } from "../components/Layouts/MainLayout";
import back_btn from "../assets/images/images/back_arrow@2x.png";
import { useNavigate, useParams } from "react-router-dom";
import { UserInfo } from "../store";
import { useRecoilState } from "recoil";
import HttpService from "../services/HttpServices";
import { Classes } from "../models";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import {
  ageLevel,
  classSizeArry,
  energyLevelArry,
  MessageString,
  proficiencyLevels,
  SpecStr,
  typeSchoolArry,
} from "../models/defaults";
import { useGetClass, useSaveClass } from "../services/Query";
import { ConfirmModal } from "../components/modals/ConfirmModal";
/**
 *
 * class edit page :/classdetail
 */

export const ClassEdit = () => {
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const [confirm, setConfirm] = useState(false);
  let { clsId } = useParams();

  const { isLoading: isSaving, mutate: onSaveClassInfo } = useSaveClass();

  const { data: clsInfo, refetch: getClsInfo } = useGetClass(clsId);
  const [changeLocation, setchangeLocation] = useState(false);
  useEffect(() => {
    if (clsId !== "new") getClsInfo();
  }, [clsId]);
  useEffect(() => {
    if (clsInfo) {
      setclass_name(clsInfo?.class_Title ?? "");
      setclass_size(clsInfo?.class_ClassSize ?? "");
      setenegry_level(clsInfo?.class_EnegryLevel ?? "");
      setschool_type(clsInfo?.school_Type ?? "");
      setschool_name(clsInfo.class_SchoolName ?? "");
      setcountry_name(clsInfo.class_Country ?? "");
      setcity_name(clsInfo.class_City ?? "");
      if (clsInfo?.class_AgeRange) {
        setClass_ages(clsInfo.class_AgeRange.split(SpecStr.split_common));
      }
      if (clsInfo?.class_ProficiencyLevel) {
        setProf_level(
          clsInfo.class_ProficiencyLevel.split(SpecStr.split_common)
        );
      }
    } else {
      if (userinfo && clsId === "new") {
        setcountry_name(userinfo.member_Country);
        setcity_name(userinfo.member_City);
        setchangeLocation(false);
      }
    }
  }, [clsInfo]);

  const navigate = useNavigate();

  const [class_name, setclass_name] = useState("");
  const [school_name, setschool_name] = useState("");
  const [country_name, setcountry_name] = useState("");
  const [city_name, setcity_name] = useState("");
  const [class_size, setclass_size] = useState("");
  const [school_type, setschool_type] = useState("");
  const [enegry_level, setenegry_level] = useState("");
  const [prof_level, setProf_level] = useState<string[]>([]);
  const [class_Ages, setClass_ages] = useState<string[]>([]);

  const handleProfLevelChange = (e: ChangeEvent<HTMLInputElement>) => {
    const item = e.currentTarget.value;
    if (e.currentTarget.checked && !prof_level.includes(item)) {
      setProf_level((prevState) => [...prevState, item]);
    } else if (!e.currentTarget.checked) {
      setProf_level((prevState) => {
        const result = [];
        for (const focus of prevState) {
          if (focus !== item) {
            result.push(focus);
          }
        }
        return result;
      });
    }
  };
  const handleClassAgesChange = (e: ChangeEvent<HTMLInputElement>) => {
    const item = e.currentTarget.value;
    if (e.currentTarget.checked && !class_Ages.includes(item)) {
      setClass_ages((prevState) => [...prevState, item]);
    } else if (!e.currentTarget.checked) {
      setClass_ages((prevState) => {
        const result: string[] = [];
        for (const focus of prevState) {
          if (focus !== item) {
            result.push(focus);
          }
        }
        return result;
      });
    }
  };
  const onSave = async () => {
    const clsData: Partial<Classes> = {
      class_Title: class_name ?? "",
      class_SchoolName: school_name ?? "",
      class_AgeRange: class_Ages.join(SpecStr.split_common),
      class_EnegryLevel: enegry_level ?? "",
      school_Type: school_type ?? "",
      class_ClassSize: class_size ?? "",
      class_ProficiencyLevel: prof_level.join(SpecStr.split_common),
      class_Country: country_name ?? "",
      class_City: city_name ?? "",
      member_Id: userinfo?.member_Id,
    };
    const formData = JSON.stringify(clsData);
    const classId = clsInfo ? clsInfo.class_Id : "new";

    onSaveClassInfo(
      { classId: classId, data: formData },
      {
        onSuccess: async () => {
          if (clsInfo) getClsInfo();
          if (userinfo) {
            const user = await HttpService.getUserInfo(userinfo.member_Id);
            if (user) setUserinfo(user);
          }
          navigate("/dashboard");
        },
      }
    );
  };
  return (
    <MainLayout>
      <div className="d-flex">
        <div className="lesson_page w-100">
          <h4 className="text-center py-3 bg_selected mb-0">
            tell us about your class
          </h4>
          <div className="description py-3 text-center">
            so we can find you the best ideas for your classroom
          </div>
          <div className="dash_content lesson_page ps-3 mx-auto one_column">
            <div className="d-flex justify-content-between">
              <img
                src={back_btn}
                alt=""
                width={125}
                onClick={() => {
                  setConfirm(true);
                }}
              />
            </div>
            <Card.Body>
              <Form className="mt-4">
                <Container>
                  <Row className="sg-row px-md-4">
                    <Col className="px-md-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          class name <br />
                          <span className="fs-6 text-muted fw-lighter">
                            we recommend the title of the course
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="class_name"
                          value={class_name}
                          onChange={(e) => setclass_name(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="sg-row px-md-4">
                    <Col className="px-md-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          class size <br />
                          <span className="fs-6 text-muted fw-lighter">
                            how many students are enrolled
                          </span>
                        </Form.Label>
                        <Form.Select
                          value={class_size}
                          onChange={(e) => setclass_size(e.target.value)}
                        >
                          <option value=""></option>
                          {classSizeArry.map((it, idx) => (
                            <option value={it} key={idx}>
                              {it}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className="px-md-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          energy level <br />
                          <span className="fs-6 text-muted fw-lighter">
                            every class has its own capacity
                          </span>
                        </Form.Label>
                        <Form.Select
                          value={enegry_level}
                          onChange={(e) => setenegry_level(e.target.value)}
                        >
                          <option value=""></option>
                          {energyLevelArry.map((it, idx) => (
                            <option value={it} key={idx}>
                              {it}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="sg-row px-md-4 mt-4">
                    <div className="px-md-4">
                      <Form.Group>
                        <Form.Label>
                          english language proficiency level
                        </Form.Label>
                      </Form.Group>
                    </div>
                  </Row>
                  <Row className="px-md-4">
                    {proficiencyLevels.map((it, idx) => (
                      <div className="px-md-4 col-lg-4 col-md-6">
                        <Form.Check
                          type="checkbox"
                          key={idx}
                          label={it.value}
                          value={it.key}
                          checked={prof_level.includes(it.key)}
                          onChange={handleProfLevelChange}
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="sg-row px-md-4 mt-4">
                    <div className="px-md-4 col-lg-4 col-md-6">
                      <Form.Group>
                        <Form.Label>student age</Form.Label>
                      </Form.Group>
                    </div>
                  </Row>
                  <Row className="px-md-4">
                    {ageLevel.map((it, idx) => (
                      <div className="ps-md-4 col-lg-4 col-md-6">
                        <Form.Check
                          type="checkbox"
                          key={idx}
                          label={it}
                          value={it}
                          checked={class_Ages.includes(it)}
                          onChange={handleClassAgesChange}
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="px-md-4 mt-3">
                    <div className="col px-md-4">
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="checkbox"
                          className="bold"
                          checked={changeLocation}
                          onClick={() => setchangeLocation((prev) => !prev)}
                          label="different from profile"
                        />
                      </Form.Group>
                    </div>
                  </Row>
                  <Row className="sg-row px-md-4">
                    <Col className="px-md-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          country <br />
                          <span className="fs-6 text-muted fw-lighter">
                            country on teacher profile
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          readOnly={!changeLocation}
                          name="country_name"
                          value={country_name}
                          onChange={(e) => setcountry_name(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="px-md-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          city <br />
                          <span className="fs-6 text-muted fw-lighter">
                            city on teacher profile
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="school_name"
                          readOnly={!changeLocation}
                          value={city_name}
                          onChange={(e) => setcity_name(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="sg-row px-md-4 d-none">
                    <Col className="px-md-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          school name <br />
                          <span className="fs-6 text-muted fw-lighter">
                            school name on teacher profile
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="school_name"
                          value={school_name}
                          onChange={(e) => setschool_name(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="px-md-4">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          type of school <br />
                          <span className="fs-6 text-muted fw-lighter">
                            type of school on teacher profile
                          </span>
                        </Form.Label>
                        <Form.Select
                          value={school_type}
                          onChange={(e) => setschool_type(e.target.value)}
                        >
                          <option value=""></option>
                          {typeSchoolArry.map((it, idx) => (
                            <option value={it} key={idx}>
                              {it}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <button
                      type="button"
                      className="fnt_robo w-180 d-inline-block bg_warn rounded_3 fs-5 py-2 px-5"
                      disabled={class_name === "" || isSaving}
                      onClick={onSave}
                    >
                      {isSaving && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          className="me-1"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      save
                    </button>
                  </div>
                </Container>
              </Form>
            </Card.Body>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={confirm}
        content={MessageString.discardText}
        leftTitle={"no"}
        rightTitle={"yes"}
        onOk={() => {
          setConfirm(false);
        }}
        onDiscard={async () => {
          navigate(-1);
          setConfirm(false);
        }}
      />
    </MainLayout>
  );
};
