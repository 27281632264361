import React, { PropsWithChildren } from "react";
import { MainHeader } from "./Headers/MainHeader";

interface Props {
  wrapClass?: string;
}
export const MainLayout = (props: PropsWithChildren<Props>) => {
  return (
    <div className={props.wrapClass}>
      <MainHeader />
      <main>{props.children}</main>
    </div>
  );
};
