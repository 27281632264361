import { useEffect, useState } from "react";
import { MainLayout } from "../components/Layouts/MainLayout";
import { confirmAdaptShow, orgActivityInAdapt, UserInfo } from "../store";
import { useRecoilState, useRecoilValue } from "recoil";
import profile from "../assets/images/icons/person.png";
import moment from "moment";
import { LeftFolder } from "../components/LeftFolder";
import { useAllActivities } from "../services/Query";
import ReactLoading from "react-loading";
import { ActivityBox } from "../components/ActivityBox";
import list_icon from "../assets/images/images/list.png";
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
/**
 *
 * unnecessary page for ui test
 */
export const Adapt = () => {
  const userinfo = useRecoilValue(UserInfo);
  const [profileImg, setProfileImg] = useState(profile);
  const [confirmAdapt, setconfirmAdaptShow] = useRecoilState(confirmAdaptShow);
  const [orgActivity, setOrgActivity] = useRecoilState(orgActivityInAdapt);

  const { data: activities, isFetching } = useAllActivities();

  useEffect(() => {
    if (userinfo?.member_Profile_imgURL) {
      setProfileImg(
        `${backend_baseUrl}/download/${userinfo?.member_Profile_imgURL}`
      );
    }
  }, [userinfo]);

  return (
    <MainLayout>
      <div className="d-flex">
        <div className="left_menu">
          <div className="my_profile text-center">
            <img src={profileImg} alt="" className="mx-auto rounded-circle" />
            <h5>
              {userinfo?.member_FirstName} {userinfo?.member_LastName}
            </h5>
            <p>
              Member since {moment(userinfo?.member_Created).format("YYYY")}
            </p>
          </div>
          <LeftFolder />
        </div>
        <div className="dash_content">
          <div className="template_list mt-4">
            <h4>ACTIVITIES</h4>
            <div className="container mt-3 ">
              <div className="cell_box row ps-3">
                {isFetching ? (
                  <div className="w-100 text-center py-4 ">
                    <ReactLoading
                      type="spinningBubbles"
                      className="mx-auto my-auto"
                      height={"80px"}
                      width={"80px"}
                      color="#666666"
                    />
                  </div>
                ) : (
                  activities?.map((it) => (
                    <div
                      className="col-xl-4 col-lg-6 mb-md-4"
                      key={it.activity_Id}
                    >
                      <ActivityBox
                        icon={list_icon}
                        activity={it}
                        disabledLesson={false}
                        onDelete={() => {}}
                        onAdapt={() => {
                          setOrgActivity(it);
                          setconfirmAdaptShow(true);
                        }}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
