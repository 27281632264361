import { useRecoilState, useRecoilValue } from "recoil";
import { UserInfo, curItem } from "../store";
import plus from "../assets/images/images/group_81.png";
import { Link, useNavigate } from "react-router-dom";
import { useMyCourse } from "../services/Query";
import ReactLoading from "react-loading";
import { Course } from "../models/Course";

export const LeftFolder = () => {
  const { data: myCourse, isLoading: isCourseLoading } = useMyCourse();
  const [curId, setCurId] = useRecoilState(curItem);
  const userInfo = useRecoilValue(UserInfo);
  const navigate = useNavigate();
  const isSelected = (item: Course, curId: string) => {
    return (
      item.course_Id == curId ||
      item._Units.filter((x) => x.id == curId).length > 0
    );
  };
  return (
    <>
      <ul className="ps-0">
        <h5 className=" text-uppercase">
          Courses
          {userInfo?._Level === 0 && (
            <img
              src={plus}
              alt=""
              className="cursor"
              width={43}
              onClick={() => {
                navigate("/edit_course/-");
              }}
            />
          )}
        </h5>
        {isCourseLoading && (
          <div>
            <ReactLoading
              type="spinningBubbles"
              className="mx-auto my-3"
              height={"80px"}
              width={"80px"}
              color="#666666"
            />
          </div>
        )}
        {myCourse?.map((it, idx) => (
          <li key={it.course_Id}>
            <Link
              to={`/dashboard/course/${it.course_Id}`}
              onClick={() => {
                setCurId(it.course_Id);
              }}
              className={curId == it.course_Id ? "selectedItem" : ""}
            >
              {it.course_Name}
            </Link>
            <ul className="ms-0 ps-0 pt-0">
              {isSelected(it, curId) &&
                it._Units &&
                it._Units
                  .filter((x) => x.isDeleted != true)
                  .sort((a, b) => a.order - b.order)
                  .map((_unit) => (
                    <li key={_unit.id} className="mb-0">
                      <Link
                        to={`/dashboard/unit/${_unit.id}`}
                        onClick={() => {
                          setCurId(_unit.id);
                        }}
                        className={curId == _unit.id ? "selectedItem" : ""}
                      >
                        {_unit.name}
                      </Link>
                    </li>
                  ))}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );
};
