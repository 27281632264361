import React, { ReactNode, useRef, useState } from "react";
import { Card, Col, Container, Overlay, Popover, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FooterSlider } from "../components/Layouts/Footers/FooterSlider";
import { MainLayout } from "../components/Layouts/MainLayout";
import help_icon from "../assets/images/icons/circle_help.svg";
import { findCata, findClass, UserInfo } from "../store";
import back_btn from "../assets/images/images/back_arrow@2x.png";
import { useRecoilState } from "recoil";
import { Classes, findRequest } from "../models";
import {
  activityTypeArry,
  LangFocus,
  lessonStages,
  Skills,
  SpecStr,
} from "../models/defaults";
/**
 *
 * Find page : /find
 */
export const Find = () => {
  //// recoil object
  const [userinfo, setUserinfo] = useRecoilState(UserInfo); /// user
  const [fclass, setClass] = useRecoilState(findClass); /// find class
  const [fCata, setCata] = useRecoilState(findCata); /// find catagory
  //// recoil objects
  const navigate = useNavigate();
  const [kind, setKind] = useState<number>(1);
  const [step, setStep] = useState<number>(1);
  const [grade, setGrade] = useState<Classes | undefined>(undefined);
  const [category, setCategory] = useState<string>("");
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const onSubCategories = (cat: string) => {
    if (subCategories.includes(cat)) {
      const nwCat: string[] = [];
      subCategories.forEach((it) => {
        if (it !== cat) nwCat.push(it);
      });
      setSubCategories(nwCat);
    } else {
      setSubCategories((prev) => [...prev, cat]);
    }
  };
  const prev = () => {
    setStep((st) => --st);
  };
  const next = () => {
    setStep((st) => ++st);
  };
  const last = () => {
    if (grade) setClass(grade?.class_Id);
    if (!grade) return;
    const reqs: findRequest = {
      languageFocus: "",
      lessonFocus: "",
      skillFocus: "",
      activity_Type: "",
      classId: grade.class_Id,
    };
    if (category === "language focus") {
      reqs.languageFocus = subCategories.join(SpecStr.split_common);
    } else if (category === "skill focus") {
      reqs.skillFocus = subCategories.join(SpecStr.split_common);
    } else if (category === "lesson stage") {
      reqs.lessonFocus = subCategories.join(SpecStr.split_common);
    } else {
      reqs.activity_Type = subCategories.join(SpecStr.split_common);
    }
    setCata(reqs);

    navigate("/find/results");
  };
  const stepCount = 3;
  const catagories = [
    "language focus",
    "skill focus",
    "lesson stage",
    "activity type",
  ];
  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState<HTMLImageElement | null>(null);
  const ref = useRef(null);
  const categoriesList = () => {
    const elements: ReactNode[] = [];
    catagories.forEach((element, idx) => {
      elements.push(
        <Col className="px-md-4 col-md-3" key={idx}>
          <button
            className={`rounded-border ${category === element ? "active" : ""}`}
            // onMouseLeave={(ev) => {
            //   setShow(false);
            // }}
            onClick={() => setCategory(element)}
          >
            <img
              src={help_icon}
              alt=""
              onClick={(ev) => {
                setShow(!show);
                setTarget(ev.currentTarget);
              }}
            />
            {element}
          </button>
        </Col>
      );
    });
    return elements;
  };
  const getHones1 = () => {
    const elements: ReactNode[] = [];
    if (category === "language focus") {
      LangFocus.slice(0, 4).forEach((it, idx) => {
        elements.push(
          <div key={idx}>
            <button
              className={`rounded-bottom-border ${
                subCategories.includes(it) ? "active" : ""
              }`}
              onClick={() => onSubCategories(it)}
            >
              <img
                src={help_icon}
                alt=""
                onClick={(ev) => {
                  setShow(!show);
                  setTarget(ev.currentTarget);
                }}
              />
              {it}
            </button>
          </div>
        );
      });
    } else if (category === "skill focus") {
      Skills.slice(0, 4).forEach((it, idx) => {
        elements.push(
          <div key={idx}>
            <button
              className={`rounded-bottom-border ${
                subCategories.includes(it) ? "active" : ""
              }`}
              onClick={() => onSubCategories(it)}
            >
              <img
                src={help_icon}
                alt=""
                onClick={(ev) => {
                  setShow(!show);
                  setTarget(ev.currentTarget);
                }}
              />
              {it}
            </button>
          </div>
        );
      });
    } else if (category === "lesson stage") {
      lessonStages.slice(0, 4).forEach((it, idx) => {
        elements.push(
          <div key={idx}>
            <button
              className={`rounded-bottom-border ${
                subCategories.includes(it) ? "active" : ""
              }`}
              onClick={() => onSubCategories(it)}
            >
              <img
                src={help_icon}
                alt=""
                onClick={(ev) => {
                  setShow(!show);
                  setTarget(ev.currentTarget);
                }}
              />
              {it}
            </button>
          </div>
        );
      });
    } else {
      activityTypeArry.slice(0, 4).forEach((it, idx) => {
        elements.push(
          <div key={idx}>
            <button
              className={`rounded-bottom-border ${
                subCategories.includes(it) ? "active" : ""
              }`}
              onClick={() => onSubCategories(it)}
            >
              <img
                src={help_icon}
                alt=""
                onClick={(ev) => {
                  setShow(!show);
                  setTarget(ev.currentTarget);
                }}
              />
              {it}
            </button>
          </div>
        );
      });
    }
    return elements;
  };
  const getHones2 = () => {
    const elements: ReactNode[] = [];
    if (category === "language focus" && LangFocus.length > 4) {
      LangFocus.slice(4, 8).forEach((it, idx) => {
        elements.push(
          <div key={idx}>
            <button
              className={`rounded-bottom-border ${
                subCategories.includes(it) ? "active" : ""
              }`}
              onClick={() => onSubCategories(it)}
            >
              <img
                src={help_icon}
                alt=""
                onClick={(ev) => {
                  setShow(!show);
                  setTarget(ev.currentTarget);
                }}
              />
              {it}
            </button>
          </div>
        );
      });
    } else if (category === "skill focus" && Skills.length > 4) {
      Skills.slice(4, 8).forEach((it, idx) => {
        elements.push(
          <div key={idx}>
            <button
              className={`rounded-bottom-border ${
                subCategories.includes(it) ? "active" : ""
              }`}
              onClick={() => onSubCategories(it)}
            >
              <img
                src={help_icon}
                alt=""
                onClick={(ev) => {
                  setShow(!show);
                  setTarget(ev.currentTarget);
                }}
              />
              {it}
            </button>
          </div>
        );
      });
    } else if (category === "lesson stage" && lessonStages.length > 4) {
      lessonStages.slice(4, 8).forEach((it, idx) => {
        elements.push(
          <div key={idx}>
            <button
              className={`rounded-bottom-border ${
                subCategories.includes(it) ? "active" : ""
              }`}
              onClick={() => onSubCategories(it)}
            >
              <img
                src={help_icon}
                alt=""
                onClick={(ev) => {
                  setShow(!show);
                  setTarget(ev.currentTarget);
                }}
              />
              {it}
            </button>
          </div>
        );
      });
    } else {
      if (category === "activity type" && activityTypeArry.length > 4) {
        activityTypeArry.slice(4, 8).forEach((it, idx) => {
          elements.push(
            <div key={idx}>
              <button
                className={`rounded-bottom-border ${
                  subCategories.includes(it) ? "active" : ""
                }`}
                onClick={() => onSubCategories(it)}
              >
                <img
                  src={help_icon}
                  alt=""
                  onClick={(ev) => {
                    setShow(!show);
                    setTarget(ev.currentTarget);
                  }}
                />
                {it}
              </button>
            </div>
          );
        });
      }
    }
    return elements;
  };
  const generalStage = () => {
    if (step === 1) {
      return (
        <Card className="ramp_card border_shadow mx-auto">
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div className="title d-flex align-items-end">
                <div className="cell">
                  <h4>finding ideas for...</h4>
                  <p>choose a class</p>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="pb-5">
            <Container>
              <Row className="sg-row px-md-4 grade">
                {userinfo &&
                  userinfo.myClasses.map((it, idx) => (
                    <Col className="px-md-4 col-md-4">
                      <button
                        className={`no-border ${grade === it ? "active" : ""}`}
                        onClick={() => setGrade(it)}
                      >
                        {it.class_Title}
                      </button>
                    </Col>
                  ))}
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer>
            <FooterSlider
              size={stepCount}
              current={step}
              isNext={grade !== undefined}
              prev={prev}
              next={next}
            />
          </Card.Footer>
        </Card>
      );
    }
    if (step === 2) {
      return (
        <Card className="ramp_card border_shadow mx-auto">
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div className="title d-flex align-items-end">
                <div className="cell">
                  <h4>search by...</h4>
                  <p>choose a category</p>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="pb-5">
            <Container>
              <Row className="sg-row px-md-4 grade">
                <React.Fragment>{categoriesList()}</React.Fragment>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer>
            <FooterSlider
              size={stepCount}
              current={step}
              isNext={category !== ""}
              prev={prev}
              next={() => {
                next();
                setSubCategories([]);
              }}
            />
          </Card.Footer>
        </Card>
      );
    }
    if (step === 3) {
      return (
        <Card className="ramp_card border_shadow mx-auto">
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div className="title d-flex align-items-end">
                <div className="cell">
                  <h4>hone your search...</h4>
                  <p>choose one or more sub-categories</p>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="pb-5">
            <Container>
              <div>
                <h3 className="vtitle">{category}</h3>
              </div>
              <div className="sg-row d-flex grade justify-content-between">
                {getHones1()}
              </div>
              <div className="sg-row d-flex grade justify-content-between">
                {getHones2()}
              </div>
            </Container>
          </Card.Body>
          <Card.Footer>
            <FooterSlider
              size={stepCount}
              current={step}
              prev={prev}
              next={next}
              isNext={subCategories.length > 0}
              last={last}
              lastText="find"
            />
          </Card.Footer>
        </Card>
      );
    }
  };
  return (
    <MainLayout>
      <div className="signup_wrap" ref={ref}>
        <img
          src={back_btn}
          className="cursor ms-2 mt-2"
          width={125}
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />

        <Container className="pt-4">
          {kind === 0 && (
            <Card className="ramp_card border_shadow mx-auto">
              <Card.Header>
                <div className="d-flex justify-content-between">
                  <div className="title d-flex align-items-end">
                    <div className="cell">
                      <h4>how specific are your needs?</h4>
                      <p>choose a search type...</p>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="pb-5">
                <Container>
                  <Row className="sg-row px-lg-4">
                    <Col className="px-md-4">
                      <button
                        className="help_btn flex-column align-items-start"
                        onClick={() => {
                          setKind(1);
                        }}
                      >
                        <h5>general search</h5>

                        <p>
                          choose this if you’re looking for a broader range of
                          inspiration, need results fast, or if you are unsure
                          which is better
                        </p>
                      </button>
                    </Col>
                    <Col className="px-md-4">
                      <button className="help_btn d-flex flex-column align-items-start">
                        <h5>targeted search</h5>
                        <p>
                          choose this if you already have a pretty good idea of
                          exactly what you’re looking for
                        </p>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          )}
          {kind === 1 && generalStage()}
        </Container>
        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref}
          containerPadding={20}
        >
          <Popover id="popover-contained">
            <Popover.Header as="h3">Help</Popover.Header>
            <Popover.Body>Contextual help will be here.</Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </MainLayout>
  );
};
