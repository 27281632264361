import { Container, Modal } from "react-bootstrap";
import ReactLoading from "react-loading";
interface Props {
  show: boolean;
  content: string;
}
export const WaitModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      className="dashboard_video"
      centered={true}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>processing...</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <div className="my-3">
            <h5
              className="text-center lh-lg"
              dangerouslySetInnerHTML={{ __html: props.content }}
            ></h5>
            <ReactLoading
              type="spinningBubbles"
              className="mx-auto my-3"
              height={"80px"}
              width={"80px"}
              color="#666666"
            />
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
