import { useEffect, useState } from "react";
import { Container, Form, Modal, Spinner } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import { MessageString } from "../../models/defaults";
import HttpService from "../../services/HttpServices";
import { useAddFolder, useAllFolders } from "../../services/Query";
import { selectActivityId, selectLessonId, UserInfo } from "../../store";
interface Props {
  show: boolean;
  onOk: () => void;
  onConfirm: () => void;
}
/**
 *
 * Add folder modal
 */
export const AddFolderModal = (props: Props) => {
  const [folderId, setFolderId] = useState<string>("");
  const userinfo = useRecoilValue(UserInfo);
  const [_activityId, set_activityId] = useRecoilState(selectActivityId);
  const [_lessonId, set_lessonId] = useRecoilState(selectLessonId);
  const { data: _folders, refetch: getFolders } = useAllFolders(
    userinfo?.member_Id
  );
  const { isLoading: isSaving, mutate: addFolderAction } = useAddFolder();
  useEffect(() => {
    if (props.show) {
      getFolders();
    }
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video"
      centered={true}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>select a folder</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <div className="my-3">
            <Form.Select
              aria-label="select a class"
              value={folderId}
              onChange={(e) => {
                setFolderId(e.target.value);
              }}
            >
              <option>select a folder</option>
              {_folders?.map((it) => (
                <option value={it.folder_Id} key={it.folder_Id}>
                  {it.folder_name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="d-flex justify-content-evenly pt-3">
            <button
              className="bg_warn rounded_3 py-2 px-4 fs-4"
              disabled={isSaving}
              onClick={() => props.onOk()}
            >
              cancel
            </button>
            <button
              className=" border_warn bg-white rounded_3 py-2 px-4 fs-4"
              disabled={folderId === "" || isSaving}
              onClick={async () => {
                if (!folderId) return;
                if (_activityId !== "") {
                  addFolderAction(
                    {
                      folderId: folderId,
                      itemId: _activityId,
                      isActivity: true,
                    },
                    {
                      onSuccess: () => {
                        props.onConfirm();
                      },
                    }
                  );
                } else {
                  addFolderAction(
                    {
                      folderId: folderId,
                      itemId: _lessonId,
                      isActivity: false,
                    },
                    {
                      onSuccess: () => {
                        props.onConfirm();
                      },
                    }
                  );
                }
              }}
            >
              {isSaving && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
              confirm
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
