import React, { ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import left_arrow from "../../../assets/images/icons/white_leftarrow.svg";
import right_arrow from "../../../assets/images/icons/white_rightarrow.svg";
interface Props {
  size: number;
  current: number;
  lastText?: string;
  first?: () => void;
  prev?: () => void;
  isNext: boolean;
  isPrepare?: boolean;
  next?: () => void;
  last?: () => void;
}
export const FooterSlider = (props: Props) => {
  const indicators = () => {
    const sliders: ReactNode[] = [];
    for (let i = 1; i <= props.size; i++) {
      if (i === props.current) {
        sliders.push(<div className="slider_indicator active" key={i}></div>);
      } else {
        sliders.push(<div className="slider_indicator" key={i}></div>);
      }
    }
    return sliders;
  };
  return (
    <div className="d-flex justify-content-between">
      <div
        className={`arrow_box d-flex justify-content-center cursor ${
          props.current === 1 ? "empty" : ""
        }`}
        onClick={() => {
          if (props.prev) props.prev();
        }}
      >
        {props.current > 1 && (
          <img src={left_arrow} alt="" className={"my-auto"} />
        )}
      </div>
      <div className="d-flex slider_box">
        <React.Fragment>{indicators()}</React.Fragment>
      </div>
      <div
        className="arrow_box right d-flex justify-content-center cursor"
        onClick={() => {
          if (!props.isNext) return;
          if (props.current < props.size) {
            if (props.next) props.next();
          } else {
            if (props.last) props.last();
          }
        }}
      >
        {props.lastText ? (
          <h3>
            {props.isPrepare && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
                role="status"
                aria-hidden="true"
              />
            )}
            {props.lastText}
          </h3>
        ) : (
          <img src={right_arrow} className={"my-auto"} alt="" />
        )}
      </div>
    </div>
  );
};
