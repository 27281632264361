import React, { useEffect, useState } from "react";
import remove_icon from "../assets/images/icons/red_minus.svg";
interface Props {
  nIndex: number;
  hideLabel?: boolean;
  type: string;
  fileName?: string;
  oId?: string;
  onRemove?: (index: number, oId?: string) => void;
  onSetFile: (idx: number, vfile: File, oId: string | undefined) => void;
}

const InputFile = (props: Props) => {
  const {
    nIndex,
    hideLabel,
    type,
    fileName: pFileName,
    oId,
    onRemove,
    onSetFile,
  } = props;
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    setFileName(pFileName || "");
  }, [pFileName]);
  const onFileChange = (e: any) => {
    if (e.target.files) {
      setFileName(e.target?.files[0].name);
      onSetFile(nIndex, e.target.files[0], oId);
    }
  };
  return (
    <div className="input-group custom_file fnt_robo">
      <input
        type="file"
        className="form-control"
        id="inputGroupFile"
        accept={type}
        onChange={onFileChange}
      />
      <label className="input-group-text" htmlFor="inputGroupFile">
        Upload
      </label>
      {/* <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          accept={type}
          onChange={(e) => {
            if (e.target.files) {
              setFileName(e.target?.files[0].name);
              onSetFile(nIndex, e.target.files[0], oId);
            }
          }}
        />
        <label className="custom-file-label browser">{fileName}</label>
      </div>*/}
      <img
        src={remove_icon}
        className={`ms-3 ${onRemove ? "" : "opt_0"} `}
        alt=""
        onClick={() => {
          onRemove && onRemove(props.nIndex, props.oId);
        }}
      />
      {!hideLabel && (
        <div className="desc">
          browser the file , then select the file to upload
        </div>
      )}
    </div>
  );
};
export default React.memo(InputFile);
